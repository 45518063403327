import { computed, reactive, readonly, ref, } from 'vue';

import { Api } from '@/services/api.service';

const state = reactive({
  event: ref(null),
  events: ref(null),
  isLoading: ref(false),
  userEvents: ref([]),
});

const loadEvent = async (id, config = {}, kolToken = null) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Event:loadEvent():submitted data', { id });
  }

  // Check for required fields and throw error as required
  if (!id) {
    throw Error('Providers.Event:loadEvent() | Event ID is required to retreive event data.');
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Event:loadEvent() | Api config is required to retreive user subscription information.');
  }
  let url = `${config.api.uri}/${config.api.version}/events/${id}`;

  if (kolToken) {
    url = url + `/?kol_token=${kolToken}&cache_buster=${Date.now()}`;
  }

  // Add API GET call to subscription URL
  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.get(url, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Event:loadEvent():response data', response);
  }

  // Set local state data on successful response
  if (response.status !== 'error') {
    state.event = response.data.data;
  }

  return response;
};

const loadEvents = async (config = {}, type, perPage, sortBy, sortOrder) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Event:loadEvents():submitted data',
      config,
      { type, perPage, sortBy, sortOrder });
  }

  // Check for required fields and throw error as required
  if (!config.api.uri) {
    return Error('Providers.Event:loadEvents() | Api config is required to retreive events.');
  }
  let url = `${config.api.uri}/${config.api.version}/events`;

  // Add defined arguments as url parameters
  let args = {};
  args.type = type;
  args.per_page = perPage;
  args.sort_by = sortBy;
  args.sort_order = sortOrder;
  Object.keys(args).forEach(key => args[key] === undefined ? delete args[key] : {});
  let params = new URLSearchParams(args).toString();
  if (params) {
    url = `${url}?${params}`;
  }

  // Add API GET call to subscription URL
  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.get(url, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Event:loadEvents():response data', response);
  }

  // Set local state data on successful response
  if (response.status !== 'error') {
    state.events = response.data.data;
  }

  return response;
};

const loadUserEvents = async (user, token, config = {}) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Event:loadUserEvents():submitted data', { user, token });
  }

  // Check for required fields and throw error as required
  if (!user) {
    throw Error(
      'Providers.Event:loadUserEvents() | User ID is required to load event data.');
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Event:loadUserEvents() | Api config is required to load user event information.');
  }
  let url = `${config.api.uri}/${config.api.version}/users/${user}/events?token=${token}`;

  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.get(url, headers);

  // if success set state.userEvents
  if ('success' === response.status) {
    state.userEvents = response.data.data;
  }

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Event:loadUserEvents():response data', response);
  }

  return response;

};

const removeUserEvents = async (config) => {
  state.userEvents = [];
  if (config.debug) {
    console.log('Providers.Event:removeUserEvents()', state.userEvents);
  }
};

const loadInstructors = async (config = {}, event, sort_by, sort_order, order = []) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Event:loadInstructors():submitted data',
      config,
      { event, sort_by, sort_order, order });
  }

  // Check for required fields and throw error as required
  if (!config.api.uri) {
    return Error(
      'Providers.Event:loadInstructors() | Api config is required to retreive instructors.');
  }
  let url = `${config.api.uri}/${config.api.version}/instructors`;

  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  let args = {};
  if (event) {
    args.event_id = event;
  }
  if (sort_by) {
    args.sort_by = sort_by;
  }
  if (sort_order) {
    args.sort_order = sort_order;
  }
  if (order.length) {
    args.instructor_order = order;
  }

  const response = await Api.post(url, args, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Event:loadInstructors():response data', response);
  }

  return response;
};

const loadEventSponsors = async (config = {}, event) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Event:loadSponsors():submitted data',
      config,
      { event });
  }

  // Check for required fields and throw error as required
  if (!config.api.uri) {
    return Error(
      'Providers.Event:loadSponsors() | Api config is required to retreive sponsors.');
  }
  let url = `${config.api.uri}/${config.api.version}/events/${event}/sponsors`;

  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.get(url, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Event:loadSponsors():response data', response);
  }

  return response;
};

const loadEventSponsorDeal = async (config = {}, event, sponsor) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Event:loadEventSponsorDeal():submitted data',
      config,
      { event, sponsor });
  }

  // Check for required fields and throw error as required
  if (!event) {
    throw Error(
      'Providers.Event:loadEventSponsorDeal() | Event ID is required to load deal data.');
  }

  if (!sponsor) {
    throw Error(
      'Providers.Event:loadEventSponsorDeal() | Sponsor ID is required to load deal data.');
  }

  if (!config.api.uri) {
    return Error(
      'Providers.Event:loadEventSponsorDeal() | Api config is required to retreive sponsor deals.');
  }
  let url = `${config.api.uri}/${config.api.version}/events/${event}/sponsors/${sponsor}/deals`;

  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.get(url, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Event:loadEventSponsorDeal():response data', response);
  }

  return response;
};

/**
 * KelbyOne Auth Provider
 * @namespace Providers.Event
 * @ignore
 * @summary KelbyOne Event Provider
 * @property { Boolean } isLoading General loading state.
 * @property { Object | null } getEvent Returns the state of the event.
 * @property { Boolean } hasEvent Returns whether a event exists.
 */
export default function EventProvider() {
  return readonly({
    loadEvent,
    loadUserEvents,
    loadEvents,
    removeUserEvents,
    loadInstructors,
    loadEventSponsors,
    loadEventSponsorDeal,
    // General
    isLoading: computed(() => Boolean(state.isLoading)),
    // Event
    getEvent: computed(() => state.event),
    hasEvent: computed(() => Boolean(state.event)),
    getUserEvents: computed(() => state.userEvents),
  });
}
